$(document).ready(function () {
    getChildrenAttended();
});

function getChildrenAttended() {
    let theseList = "";
    $.ajax({
        type: "GET",
        url: `${env.API_URL}/count-children-attended`,
        success: function (data) {
            const countTotal = data.data;
            theseList =
                `<h2 class="numRoling" data-num="200">+${countTotal}</h2>` +
                `<h3>Crianças atendidas</h3>`

            $("#children-attended").html(theseList);
        },
        error: "error",
    });
}