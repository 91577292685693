$(document).ready(function () {
  $(".loading").addClass("active");
  accordionListSchool = "";
  $.ajax({
    type: "GET",
    data: {},
    url: `${env.API_URL}/escolas/ElementarySchool`,
    success: function (data) {
      escolas = data.data;
      escolas.forEach((escola) => {
        let name = escola.name.replace("ESCOLA MUNICIPAL", "");
        accordionListSchool +=
          ` <div class="school-accordion"
        data-maps="https://maps.google.com/maps?q=${escola.latitude},${escola.longitude}&hl=pt-br&z=15&amp;output=embed"
      >
        <a id="btn-accordion" class="school-accordion-name">
          <div>
            <span onclick="addClassActiveInAccordion(${escola.id})"></span>
            ${name}
          </div>
        </a>
        <div class="school-accordion-item" id="accordion-${escola.id}">
          <div class="school-addres">
            <p><strong>Endereço: </strong>${escola.tipo_logradouro ?? ''}` +
          " " +
          `${escola.logradouro}, ${escola.numero} - ${escola.bairro} </p>
            <p><strong>Telefone/Ramal:</strong> (12) ${mascaraDeTelefone(
              escola.tel_numero,
            )} / 1300</p>
          </div>
        </div>
      </div> `;
      });
      $("#accordion-list-school").html(accordionListSchool);
      $(".loading").removeClass("active");
    },
  });
});

function addClassActiveInAccordion(id) {
  const elem = document.getElementById(`accordion-${id}`);
  const result = elem.classList.contains('active')
  if (!result){
      closeAllAccordion();
      elem.classList.add("active");
  }
  else elem.classList.remove("active");
}

function closeAllAccordion() {
  let activeElements = document.querySelectorAll('.school-accordion-item');
  for (j = 0; j < activeElements.length; j++) {
      if (activeElements[j].className.includes('active')) {
          activeElements[j].classList.remove('active');
      }
  }
}

function mascaraDeTelefone(telefone) {
  let textoAtual = '';
  if(!telefone) textoAtual = '00000000';
  else textoAtual = telefone;
  const isCelular = textoAtual.length === 9;
  let textoAjustado;
  if (isCelular) {
    const parte1 = textoAtual.slice(0, 5);
    const parte2 = textoAtual.slice(5, 9);
    textoAjustado = `${parte1}-${parte2}`;
  } else {
    const parte1 = textoAtual.slice(0, 4);
    const parte2 = textoAtual.slice(4, 8);
    textoAjustado = `${parte1}-${parte2}`;
  }
  telefone = textoAjustado;
  return textoAjustado;
}
