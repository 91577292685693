// todos os protocolos solicitados
$(document).ready(function () {
  let theseList = '';

  $.ajax({
    type: 'GET',
    data: {},
    url: `${env.API_URL}/escolas/nursery`,
    success: function (data) {
      schools = data.data;
      schools.forEach((item, index) => {
        let name = item.name.replace('CRECHE MUNICIPAL', ''); 
        $('#school-select').append('<option value=' + item.id + '>' + name + '</option>');
      });
      $('.loading').removeClass('active');
    },
    error: 'error',
  });

});
