$(document).ready(function () {
  let sendData = {};
  let user = sessionStorage.getItem("user");
  if (user != null || user != undefined) {
    if (
      typeof sendData === "object" &&
      !Array.isArray(sendData) &&
      sendData !== null &&
      isJsonString(user) == true
    ) {
      userData = JSON.parse(user);
      sendData["protocolo"] = userData[0].protocolo;
      sendData["senha"] = userData[0].senha;
      $(".loading").addClass("active");

      $.ajax({
        type: "POST",
        url: `${env.API_URL}/protocolos/acessar`,
        data: sendData,
        success: function (data) {
          if (data.data == null) {
            sessionStorage.removeItem("user");
          } else {
            if (
              window.location.href ==
              `${env.WEB_URL}/acompanhamento-solicitacao/`
            ) {
              window.location.href = `${env.WEB_URL}/minha-situacao`;
            }
          }
        },
        error: "error",
      });
      $(".loading").removeClass("active");
    }
  }
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
});
