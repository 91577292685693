$(document).ready(function () {
  $("#download-protocolo").on("click", function (e) {
    var protocolo_code = $("#protocolo_code");
    var protocolo = protocolo_code.attr("data-protocolo");
    window.open(`${env.API_WEB_URL}/pdf/protocol/download/${protocolo}`, '_blank');
  });
});

function PrintElem()
{
  var protocolo_code = $("#protocolo_code");
  var protocolo = protocolo_code.attr("data-protocolo");
  var printWindow = window.open(`${env.API_WEB_URL}/pdf/protocol/${protocolo}`, '_blank');
  var printAndClose = function () {
    if (printWindow.document.readyState == 'complete') {
      printWindow.document.write('<html><head><title>' + protocolo  + '</title>');
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  }  
  printAndClose;
  return true;
}