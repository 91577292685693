function sweetRequestReceived(nameElement) 
{
  if(nameElement != ''){     
    $(`.${nameElement}`).html(MESSAGES.REQUEST_RECEIVED);
  }

  Swal.fire({
      icon: 'warning',
      title: 'Importante!',
      confirmButtonText: 'Fechar',
      customClass: {
      popup: 'sweet-w90',
      
      },
      confirmButtonColor: 'rgb(85, 202, 98)',
      html: `<div>${MESSAGES.REQUEST_RECEIVED}</div>`
  });
}

function sweetRequestReceivedSchool(nameElement) 
{
  if(nameElement != ''){     
    $(`.${nameElement}`).html(`<p>Sua solicitação foi recebida e agora está em processo de validação na <b>Secretaria Municipal de Educação de Cruzeiro/SP</b>.</p><p>O prazo de liberação da solicitação para acompanhamento é de <b>72 horas úteis.</b></p>`);
  }

  Swal.fire({
      icon: 'warning',
      title: 'Importante!',
      confirmButtonText: 'Fechar',
      customClass: {
      popup: 'sweet-w90',
      
      },
      confirmButtonColor: 'rgb(85, 202, 98)',
      html: `<div><p>Sua solicitação foi recebida e agora está em processo de validação na <b>Secretaria Municipal de Educação de Cruzeiro/SP</b>.</p><p>O prazo de liberação da solicitação para acompanhamento é de <b>72 horas úteis.</b></p></div>`
  });
}

function sweetRequestAccepted(nameElement)
{
  if(nameElement != ''){   
    $(`.${nameElement}`).html(MESSAGES.REQUEST_ACCEPTED);
  }
  
  Swal.fire({
      icon: 'success',
      title: 'Sua solicitação foi validada pela Secretaria Municipal de Educação de Cruzeiro/SP!',
      confirmButtonText: 'Fechar',
      customClass: {
        popup: 'sweet-w90',
        
      },
      confirmButtonColor: 'rgb(85, 202, 98)',
      html: MESSAGES.REQUEST_ACCEPTED
    });
}

function sweetRequestAcceptedSchool(nameElement, name, tel, address)
{
  const text = `<p>Agora você deverá comparecer na Unidade Escolar georreferenciada, para efetivar a matrícula.</p>
  <p>Dados da Unidade Escolar georreferenciada:</p>
  <p style="margin-bottom: 0;"><b>Escola:</b> ${name}</p><p style="margin-bottom: 0;"><b>Telefone:</b> ${tel}</p><p style="margin-bottom: 0;"><b>Endereço:</b> ${address}</p>
  <br /><p>Lembre-se de levar cópias dos seguintes documentos:</p>
  <ul><li>Certidão de nascimento</li><li>Carteira de vacinação em dia</li><li>Carteirinha do SUS</li><li>Comprovante de residência</li><li>RG e CPF dos responsáveis</li></ul>
  <br /><p>Atenciosamente,<br /><b>Equipe da Secretaria Municipal de Educação de Cruzeiro/SP.</b></p>`;
  
  if(nameElement != ''){   
    $(`.${nameElement}`).html(text);
  }
  
  Swal.fire({
      icon: 'success',
      title: 'Sua solicitação foi validada pela Secretaria Municipal de Educação de Cruzeiro/SP!',
      confirmButtonText: 'Fechar',
      customClass: {
        popup: 'sweet-w90',
        
      },
      confirmButtonColor: 'rgb(85, 202, 98)',
      html: text
    });
}

function sweetRequesWithAlert(telefone, celular, nameElement)
{
  
  var text = MESSAGES.REQUEST_WITH_ALERT.replace('$changeTel$', telefone);
  if(nameElement != ''){  
    $(`.${nameElement}`).html(text);
  }

  Swal.fire({
    icon: 'warning',
    title: 'Atenção!',
    confirmButtonText: 'Fechar',
    customClass: {
      popup: 'sweet-w90',
      
    },
    confirmButtonColor: 'rgb(85, 202, 98)',
    html: `<div>${text}</div>`
  });
}

function sweetProtocolSubmissionConfirmation() 
{
  Swal.fire({
    icon: 'warning',
    title: 'Importante!',
    confirmButtonText: 'Fechar',
    customClass: {
      popup: 'sweet-w90',
     
    },
    confirmButtonColor: 'rgb(85, 202, 98)',
    html: `<div>${MESSAGES.SUBMISSION_CONFIMATION}</div>`
  });
}

function sweetProtocolSubmissionConfirmationSchool() 
{
  Swal.fire({
    icon: 'warning',
    title: 'Importante!',
    confirmButtonText: 'Fechar',
    customClass: {
      popup: 'sweet-w90',
     
    },
    confirmButtonColor: 'rgb(85, 202, 98)',
    html: `<div>${MESSAGES.SUBMISSION_CONFIMATION_SCHOOL}</div>`
  });
}

function sweetAlertUnfilledFields() {
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Por gentileza preecha os campos em vermelho',
    showDenyButton: true,
    denyButtonText: 'Fechar',
    showConfirmButton: false
  });
}

function sweetGenericError(message) {
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: message,
    showDenyButton: true,
    denyButtonText: 'Fechar',
    showConfirmButton: false
  });
}