$(document).ready(function () {
  var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, "").length === 11
        ? "(00) 00000-0000"
        : "(00) 0000-00009";
    },
    spOptions = {
      onKeyPress: function (val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      },
    };

  $("#telefone").mask(SPMaskBehavior, spOptions);
  $("#data_nascimento").mask("00/00/0000");
  $("#cep").mask("00000-000");

  $("#form-vaga-creche").on("submit", function (e) {
    e.preventDefault();
    $(this).find(":button[type=submit]").attr("disabled", true);

    var formData = new FormData($("#form-vaga-creche").get(0));
    let emptyInput = 0;

    $("#form-vaga-creche :input").each(function () {
      if (
        $(this).val() === "" &&
        $(this).attr("type") != "submit" &&
        $(this).attr("id") != "complemento" &&
        $(this).attr("id") != "file" &&
        $(this).attr("id") != "file-vacancies"
      ) {
        $(this).css("border", "1px solid red");
        emptyInput++;
      } else $(this).css("border", "1px solid #3e4347");
      if ($(this).attr("id") == "telefone") {
        const phoneNumberError = phoneNumber();
        if (phoneNumberError.error) {
          $(this).css("border", "1px solid red");
          emptyInput++;
        }
      }
      if ($(this).attr("id") == "cep") {
        var cepInput = document.getElementById("cep");
        var cep = cepInput.value.replace(/\D/g, "");
        if (cep.length < 8) {
          $(this).css("border", "1px solid red");
          emptyInput++;
        }
      }
    });
    if ($("input[name='pcd']:checked").val() == "1" && !$("#file").val()) {
      $("#file").css("border", "1px solid red");
      emptyInput = emptyInput + 1;
    } else $("#file").css("border", "none");

    formData.append("pcd", $("input[name='pcd']:checked").val() ?? 0);

    if (emptyInput == 0) {
      $(".loading").addClass("active");

      $.ajax({
        type: "POST",
        url: `${env.API_URL}/vagas-em-creches`,
        data: formData,
        processData: false,
        contentType: false,
        success: function (data) {
          if (data.error == true) sweetGenericError(data.message);
          else {
            $("#solicitacao-text").html(
              `<p>*O aluno será redirecionado para a creche mais próxima da sua localização</p>` +
                `<p id='protocolo_nome'><i>Olá, ${data.data.nome_responsavel}</i></p>` +
                "<p>Acompanhe sua solicitação na página Vagas em Creche</p>" +
                `<p id='protocolo_code' data-protocolo=${data.data.protocolo} >Protocolo: <strong> ${data.data.protocolo}</strong></p>` +
                `<p id='protocolo_senha' data-senha=${data.data.senha} >Senha: <strong>${data.data.senha}</strong></p>` +
                `<p id='protocolo_nome_aluno' data-nome_aluno=${data.data.nome_aluno} >Nome do Aluno: <strong>${data.data.nome_aluno}</strong></p>` +
                `<p id='protocolo_nome_creche' data-nome_creche=${data.data.nome_escola} >Creche: <strong>${data.data.nome_escola}</strong></p>`
            );
            $(".fwdMessage").fadeIn("fast");
            $("#form-vaga-creche").each(function () {
              this.reset();
            });
            $("#form-vaga-creche").addClass("active");
            sweetProtocolSubmissionConfirmation();
          }
          $(".loading").removeClass("active");
        },
        error: "error",
      });
    } else sweetAlertUnfilledFields();
    setTimeout(() => {
      $(this).find(":button[type=submit]").attr("disabled", false);
      $(this).find(":button[type=submit]").val("Submit");
    }, 5000);
  });
});

function phoneNumber() {
  var phoneNumber = document.getElementById("telefone").value;
  var cleanedPhoneNumber = phoneNumber.replace(/[\(\)\-\s]/g, "");

  var phoneRegex = /^\d{11}$/;
  if (!phoneRegex.test(cleanedPhoneNumber))
    return {
      error: true,
    };
  return {
    error: false,
  };
}
