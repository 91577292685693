const MESSAGES = {
    SUBMISSION_CONFIMATION: '<p>Solicitação realizada com sucesso!</p><p>Agora você pode fazer o download, imprimir ou acessar sua solicitação mediante o protocolo gerado. </p> <p>Não é necessário a apresentação desse protocolo na unidade da creche ou na <b>Secretaria Municipal de Educação de Cruzeiro/SP</b>.</p> <p>O direcionamento da creche não é opcional; o critério para escolha é por georreferenciamento, ou seja, a creche selecionada será a mais próxima do endereço informado na solicitação.',
    REQUEST_RECEIVED: '<p>Sua solicitação foi recebida e agora está em processo de validação na <b>Secretaria Municipal de Educação de Cruzeiro/SP</b>.</p><p>O prazo de liberação da solicitação para acompanhamento é de <b>24 horas úteis.</b></p><p>Nesse momento, não será possível visualizar sua posição na lista de espera.</p>',
    REQUEST_ACCEPTED: '<p>Agora você pode visualizar sua posição na lista de espera abaixo.</p><p>Importante:</p><ul stye="list-style: inside !important; margin-bottom: 10px;"><li style="list-style: inside !important; margin-bottom: 10px;">A ordenação segue o critério de Data de Solicitação;</li><li style="list-style: inside !important; margin-bottom: 10px;">Você conseguirá visualizar na íntegra apenas os seus dados cadastrais, os demais serão ocultados, apresentando apenas as inicias do Nome do Responsável e Nome da Criança;</li><li style="list-style: inside !important; margin-bottom: 10px;">Para matricula a própria creche entrará em contato pelo número de telefone e e-mail preenchidos na solicitação realizada do seu protocolo;</li></ul>',
    REQUEST_WITH_ALERT: '<p>A <b>Secretaria Municipal de Educação de Cruzeiro/SP</b>, informa que tentou contato com você pelo número $changeTel$ para tratar de questões sobre sua solicitação. Tentaremos novamente em breve.</p><p>Você também pode entrar em contato conosco pelo telefone (12) 3600-3312 ou pelo e-mail planejamento.edu@cruzeiro.sp.gov.br. Lembre-se de informar o protocolo.</p>',
    SUBMISSION_CONFIMATION_SCHOOL: '<p>Solicitação realizada com sucesso!</p><br /><p>A <b>Secretaria Municipal de Educação de Cruzeiro/SP</b> recebeu seus dados e retornará por e-mail em até <b>72 horas</b> com as informações para você realizar a matrícula do seu filho na Rede Municipal de Educação.</p><p>O direcionamento da escola não é opcional; o critério para escolha é por georreferenciamento, ou seja, a escola selecionada será a mais próxima do endereço informado na solicitação</p>',
    REQUEST_RECEIVED_SCHOOL: '<p>Sua solicitação foi recebida e agora está em processo de validação na <b>Secretaria Municipal de Educação de Cruzeiro/SP</b>.</p><p>O prazo de liberação da solicitação para acompanhamento é de <b>72 horas úteis.</b></p>',
}

$(document).ready(function() {
// Global
app.global = {
    init: function(){ // Load all global functions here
        app.global.loadHeader();
    },
    loadHeader: function(){ // Some specific function
    }
}

// Run the global stuff
app.global.init();
});


function formatShortName(name)
{
    let originalName = name.split('');
    return originalName.join('.');
}

function firstLetterUppercase(string)
{
    string = string.toLowerCase();
    const arr = string.split(" ");

    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
}
  
function changeHour(date) 
{
    var date = new Date(date);
    var newDate = date.toLocaleDateString(
        navigator.language, {hour: '2-digit', minute:'2-digit', second: '2-digit'}
    );
    return newDate.replace(',', ' ');
}

function changeDate(date) 
{
    var date = new Date(date);
    var newDate = date.toLocaleDateString(
        navigator.language, {timeZone: 'UTC'}
    );
    return newDate.replace(',', ' ');
}

// Modal
if(document.getElementById('modal')){
    const openModal = document.getElementById('modal');
    const closeModal = document.getElementById('btn-close');
    
    document.addEventListener("DOMContentLoaded", function(){
        openModal.showModal();
    })
    
    closeModal.addEventListener("click", function(){
        openModal.close();
        openModal.style.display = 'none';
    })
    
}

// Search Pages
function searchPages(inputId) 
{
    const input = document.getElementById(inputId);
    if(input.value != null && input.value != ''){
        window.location = `${env.WEB_URL}/busca/?site=${input.value}`;
    } else {
        Swal.fire({
            icon: 'warning',
            title: 'Atenção!',
            confirmButtonText: 'Fechar',
            text: 'Valor no campo de busca não pode ser vazio',
          });
    }
}
