// Listando todos Turma/Ano
$(document).ready(function () {
  let theseList = "";

  $.ajax({
    type: "GET",
    data: {},
    url: `${env.API_URL}/protocolos/turma-ano?typesOfTeaching=nursery`,
    success: function (data) {
      turmaAno = data.data;
      turmaAno.forEach((item, index) => {
        $("#turma-ano").append(
          "<option value=" +
            item.id +
            ">" +
            item.name +
            "</option>",
        );
      });
      $(".loading").removeClass("active");
    },
    error: "error",
  });
});
