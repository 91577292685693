jQuery(document).ready(function ($) {

  function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  $(this).on("click", ".actMnuMobile", function (e) {
    e.preventDefault();
    $(this).toggleClass("active");
    $(".menu-responsive").toggleClass("active");
  });

  $(this).on("click", ".actMnuSideBar", function (e) {
    e.preventDefault();
    $(this).toggleClass("active");
    $(".side-bar-menu").toggleClass("active");
  });

  $(this).on("click", ".side-bar-menu", function (e) {
    sleep(100).then(() => {
      $(this).removeClass("active");
      $(".actMnuSideBar").removeClass("active");
    })
  });


  $(".single-item").slick({
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    focusOnChange: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          arrows: false,
        },
      },
    ],
  });
  
  $(".multiple-items").slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3200,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  });
  
  $(".multiple-items-schools-blog").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3200,
    dots: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $("#slideNutricao").slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3200,
    dots: true,
    arrows: false,
  });

  if ($("#wpadminbar").length) {
    $("header").css("top", "32px");
  }

  if ($(window).width() < 1025) {
    $(this).on("click", ".hasSubMnu", function (e) {
      e.preventDefault();

      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(".subMnu").removeClass("active").slideToggle("fast");
      } else {
        $(this).addClass("active");
        $(".subMnu").slideToggle("fast").addClass("active");
      }
    });
  }

  if ($(".page-vagas-creche").length) {
    rolingNumber($(".numRoling"));
  }
  var filter = "";

  if ($("#info-button").length) {
    $("#info-button").on("click", () => openWarningModal($("#warning-modal")));
  }

  $(this).on("change", "input[name=filter]", function () {
    filter = $("input[name=filter]:checked").val();
  });

  $(this).on("click", "#btnSubmit", function () {
    load_posts(filter);
  });

  function load_posts(filter) {
    var str = "&filter=" + filter + "&action=more_post_ajax";
    $.ajax({
      type: "POST",
      dataType: "html",
      url: ajax_posts.ajax_url,
      data: str,
      success: function (data) {
        // $(".loading").removeClass("active");
        var $data = $(data);
        $("#ajax-div").html($data);
        loadCarousel();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR + " :: " + textStatus + " :: " + errorThrown);
      },
    });
    return false;
  }
  
  showFileInput();
});

function isModalClosed(modal) {
  const modal_name = modal[0].getAttribute("name");
  const alreadyClosed = sessionStorage.getItem(`modal_${modal_name}_is_closed`);

  if (alreadyClosed) {
    enablePageScroll();
    modal.toggleClass("closed");
    return true;
  }

  return false;

}

function enablePageScroll() {
  document.body.style.overflow = 'auto';
}

function disablePageScroll() {
  document.body.style.overflow = 'hidden';
}

function openWarningModal(modal) {
  if (modal) {
    modal.toggleClass("closed");
    disablePageScroll();
  }
}

function closeWarningModal(modal) {
  if (modal) {
    modal.toggleClass("closed");
    enablePageScroll();
    const modal_name = modal[0].getAttribute("name");
    sessionStorage.setItem(`modal_${modal_name}_is_closed`, true);
  }
}

function rolingNumber(v) {
  var numero = v[0];
  var min = 0;
  var max = v.data("num");

  var duration = 2500; // 5 segundos

  for (var i = min; i <= max; i++) {
    setTimeout(
      function (nr) {
        numero.innerHTML = "+" + nr;
      },

      (i * duration) / max,
      i
    );
  }
}

function submitForm(name = null) {
  if (name != null) {
    try {
      var formObject = document.forms[name];
      formObject.submit();
    } catch (error) {
      console.log('Erro ao enviar formulário: ' + error)
    }
  }
}

// Instantiating the global app object
var app = {};

function collapse(name, column)
{
    var element = `collaps-${column}-${name}`;
    var icon = `${column}-${name}`;

    let result = $(`.${element}`)[0].className.includes("collapsed");
    $('.collapsible').addClass("collapsed");
    if (!result) $(`.${element}`).addClass("collapsed");
    else $(`.${element}`).removeClass("collapsed");

    let iconResult = $(`.${icon}`)[0].className.includes("rotate");
    $('.bi-caret-right-fill').removeClass("rotate");
    if (!iconResult) $(`.${icon}`).addClass("rotate");
    else $(`.${icon}`).removeClass("rotate");
}

function showFileInput()
{
  let value = $("input[name='pcd']:checked").val();

  if (value == 1) $(`.file-input`).addClass("active");
  else $(`.file-input`).removeClass("active");
}

function collapseSetor(id)
{
    var element = `cv-${id}`;
    let result = $(`.${element}`)[0].className.includes("show");
    if (!result) {
      $(`.${element}`).addClass("show");
      document.getElementById(id).innerHTML="Ver menos";
    } else {
      $(`.${element}`).removeClass("show");
      document.getElementById(id).innerHTML="Ver mais";
    }
}

$(document).ready(function () {
  $(this).on('click', '.school-accordion', function (e) {
      e.preventDefault()
      $('.school-accordion').removeClass('active')
      $(this).toggleClass('active')
      $('.school-location iframe').attr('src', $(this).data('maps'))
  })
})