$(document).ready(function () {
  $(".content-login").on("submit", function (e) {
    e.preventDefault();
    let userLogin = {};
    let emptyInput = 0;

    $(".content-login :input").each(function () {
      if ($(this).val() === "" && $(this).attr("type") != "submit") {
        $(this).css("border", "1px solid red");
        emptyInput++;
      } else {
        userLogin[this.name] = $(this).val();
        emptyInput = 0;
        $(this).css("border", "1px solid #3e4347");
      }
    });
    if (emptyInput == 0) {
      $(".alert-user").removeClass("active");
      $.ajax({
        type: "POST",
        url: `${env.API_URL}/protocolos/acessar`,
        data: userLogin,
        success: function (data) {
          if (data.data.length == 0) $(".alert-user").addClass("active");
          else {
            sessionStorage.setItem("user", JSON.stringify(data.data));
            $(".alert-user").removeClass("active");
            window.location.href = `${env.WEB_URL}/minha-situacao`;

          }
        },
        error: "error",
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por gentileza preecha os campos em vermelho'
      });
    }
  });
});
